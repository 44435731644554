import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/en/layout";
import SEO from "../../components/seo";

class PolicyPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div style={{ textAlign: "center", padding: "10%" }}>
          <SEO title="Privacy Policy" />
          <h1
            className="title"
            style={{ fontSize: "2em", fontWeight: "bold", color: "#00ff00" }}
          >
            {" "}
            Privacy Policy{" "}
          </h1>
          <h2 className="subtitle"> Terms of Service </h2>
        </div>
        <div style={{ textAlign: "left", padding: "0% 10%" }}>
          <h2 className="subtitle"> Who are we? </h2>

          <p>
            {" "}
            We are a company providing environmental engineering services -{" "}
            <b> Rexer </b>, our website address is:{" "}
            <a href="https://rexer.pl"> https://rexer.pl </a>{" "}
          </p>
          <br />

          <div id="gdpr" style={{ paddingTop: "100px" }}></div>
          <h2 className="subtitle"> GDPR </h2>
          <p>
            {" "}
            The protection of your personal data is carried out in accordance
            with the requirements of generally applicable laws, including:
            Regulation (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016. on the protection of individuals with
            regard to the processing of personal data and on the free movement
            of such data, and repealing Directive 95/46 / EC (General Data
            Protection Regulation), the so-called GDPR.{" "}
          </p>
          <br />
          <h2 className="subtitle">
            {" "}
            The basis for the processing of personal data:{" "}
          </h2>

          <p>
            {" "}
            The processing of personal data of each of the Users submitting the
            application via the form takes place in accordance with the law and
            in accordance with applicable regulations and does not violate the
            rights and freedoms of the data subject.
            <b>
              {" "}
              The basis for data processing is the User's consent referred to in
              art. paragraph 1 (a) and Article 9 (2) (a) of the GDPR.
              <br /> <br />
              The user agrees to the processing of data by indicating them in
              the contact form.{" "}
            </b>{" "}
          </p>
          <br />
          <h2 className="subtitle">
            {" "}
            Consent to the processing of personal data:{" "}
          </h2>

          <p>
            {" "}
            I agree to the processing of my personal data, i.e. name and e-mail
            address, by Rexer, registered in Wrocław at ul. Legnicka 55F / 330,
            based in Wrocław at ul. Legnicka 55F / 330, in order to send the
            newsletter and commercial information by e-mail. Providing data is
            voluntary. The basis for data processing is my consent. Data
            recipients may be employees of the data controller. I have the right
            to withdraw my consent at any time. Personal data will be processed
            until the consent is revoked, and after such revocation, for the
            period of limitation of claims due to the data controller and in
            relation to him. I have the right to request the administrator to
            access my personal data, rectify it, delete or limit processing, as
            well as the right to lodge a complaint with the supervisory body.
            Contact to the personal data administrator:{" "}
            <a href="mailto:biuro@rexer.pl"> biuro@rexer.pl </a>{" "}
          </p>

          <br />
          <h2 className="subtitle">
            {" "}
            What personal data we collect and why we collect it:{" "}
          </h2>
          <h3 className="subtitle"> Media: </h3>
          <p>
            {" "}
            We collect various types of media from users in order to develop our
            business and facilitate work organization, also from website
            visitors. By adding a file to our server, you give us the right to
            use, delete or publish it. At the same time, we are not responsible
            for the content sent to us by anonymous users, inappropriate content
            will be moderated and removed.{" "}
          </p>
          <br />
          <h3 className="subtitle"> Contact forms: </h3>
          <p>
            {" "}
            In the case of contact forms, the data we save are your name and
            email address, telephone number and answers to questions from the
            forms, as well as shared files.{" "}
          </p>

          <div id="cookies" style={{ paddingTop: "100px" }}>
            {" "}
          </div>
          <h2 className="subtitle"> Cookies </h2>
          <p>
            {" "}
            If you visit the login page, we will create a temporary cookie to
            check if your browser accepts cookies. This cookie contains no
            personal data and will be discarded when you close your browser.
            When logging in, we additionally create a few cookies needed to save
            your login information and selected screen options. Login cookies
            last for two days, and screen options cookies last for a year. If
            you select the "Remember me" option, login will expire after two
            weeks. If you log out of your account, the login cookies will be
            deleted. If you edit or publish an article, an additional cookie
            will be saved in your browser. This cookie does not contain any
            personal data, it simply indicates the ID of the article you just
            edited. It expires after 1 day. If you edit or publish an article,
            an additional cookie will be saved in your browser. This cookie does
            not contain any personal data, it simply indicates the ID of the
            article you just edited. It expires after 1 day. The website uses
            cookies to identify browser Users while using the website. Cookies
            contain a small amount and text that is readable only by the party
            that sends them. Thanks to the collected information, we can obtain
            knowledge about how often you visit our website and which of its
            elements are of greatest interest to you. Cookies cannot be used to
            infect your device with viruses or other malicious software
            (malware). Each individual cookie consists of four basic parts:
            website name: the name of the domain or subdomain that set the
            cookie; cookie name: the cookie has a name that is unique to the
            page that set it; expiry date: some cookies expire after closing the
            browser (so-called session cookie), other cookies will be
            automatically deleted only after reaching the expiry date that has
            been set (so-called persistent), value: this is information in the
            cookie that the website uses to "remember" the previous visit. In
            cookies, we store basic information about Users (e.g. ID) and
            information needed to optimize and correctly display the content of
            the pages. This information is used to: recognize users logging in
            to the website, register Users' visits, which allows to collect data
            helpful in introducing improvements in the content and in the way of
            website navigation. Users of our website at any time stop providing
            information to the website by deleting cookies stored on their end
            devices by our website. To do this, you need to change the settings
            of the currently used web browser. Users have the right to refuse to
            save and read cookies on their device (computer, phone). To do this,
            they must select the appropriate settings in the options of the web
            browser. Configuring the browser in a way that will block the
            installation of cookies for specific websites selected by the User
            or for all websites may result in the loss of certain
            functionalities and make it difficult or impossible to fully use its
            capabilities. In accordance with the requirements of generally
            applicable law, confirmation of consent to the use of cookies is
            considered to be the configuration of the browser that allows the
            installation of cookies on the computer. Communication between the
            User's computer and the server servicing the website is encrypted
            during data collection. In addition, the databases are protected
            against access by third parties.{" "}
          </p>
          <br />
          <h2 className="subtitle"> Embedded content from other sites: </h2>
          <p>
            {" "}
            Articles on this site may contain embedded content (e.g. videos,
            pictures, articles, etc.). Embedded content from other websites
            behaves in a similar way as if the user has visited a specific
            website directly. Sites may collect information about you to a
            limited extent through the use of our server, i.e. interactions from
            the graphical interface, use cookies, attach additional external
            tracking systems, and monitor your interactions with embedded
            material, including tracking your interactions with embedded
            material, if you have one. account and you are logged in to that
            site. All activities performed as part of embedded content from
            other websites are subject to the privacy policies provided by the
            owners of individual websites. The user then has the rights
            specified in the aforementioned documents, Rexer is not responsible
            for the actions of users performed within the content embedded from
            external websites.{" "}
          </p>
          <br />
          <h2 className="subtitle"> Statistics analysis </h2>
          <p>
            {" "}
            We analyze the statistics of our website's recipients using Google
            Analytics software, it provides anonymous information about the
            users of our website, which we use to improve it in terms of
            technology and website content. As reported by Google in{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/analytics/answer/3379636?hl=en"
            >
              {" "}
              Google Ads Data Protection Terms: Service Information{" "}
            </a>
            , Google Analytics collects the following types of personal data:{" "}
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li> Online identifiers including cookies </li>
            <li> IP addresses and device IDs </li>
            <li> Customer IDs </li>
            <li>
              {" "}
              We use the feature: <b> anonymize IP </b> to protect user data as
              required by some countries, such as Germany, pursuant to the GDPR.{" "}
            </li>
          </ul>
          <br />
          <h2 className="subtitle"> Who we share data with: </h2>
          <p>
            {" "}
            The data generated by google analytics software is shared with
            Google. All website data is shared only with the members of the
            Rexer team, ie the administrator and its employees.{" "}
          </p>
          <br />
          <h2 className="subtitle"> How long do we keep your data: </h2>
          <p>
            {" "}
            For users who registered on our website (if any), we also store
            personal information entered in the profile. All users can view,
            edit or delete their personal information at any time (except their
            username, which cannot be changed). The site administrators can also
            view and modify this information.{" "}
          </p>
          <br />
          <h2 className="subtitle"> What are your rights to your data: </h2>
          <p>
            {" "}
            If you have a user account, you can request delivery of a file with
            an exported set of your personal data in our possession, including
            all those provided by you. You can also request that we erase any
            personal data we hold about you. This does not include any data that
            we are required to keep for administrative, legal or security
            purposes.{" "}
          </p>
          <br />
          <h2 className="subtitle"> Each User has the right to: </h2>
          <ul style={{ listStyle: "inside" }}>
            <li> request access to your personal data, </li>
            <li> rectification of data, </li>
            <li>
              {" "}
              request to supplement incomplete personal data, including by
              providing an additional statement,{" "}
            </li>
            <li> deletion of data or limitation of their processing, </li>
            <li> object to further processing of personal data, </li>
            <li> transfer of personal data. </li>
            <li>
              {" "}
              lodging a complaint to the supervisory body, i.e. the President of
              the Personal Data Protection Office.{" "}
            </li>
          </ul>
          <br />
          <p>
            {" "}
            The trademarks and product marks of REXER, REXER engineering are the
            property of REXER sp.z o.o. sp. k. and are protected by copyright.
            Other logos, logos and other elements not owned by REXER sp.z o.o.
            sp. k. have been used for informational purposes only and are the
            property of their rightful owners. REXER sp. Z o.o. sp. k. is not
            responsible for the content of external links.{" "}
          </p>

          <br />
          <div style={{ textAlign: "center", padding: "5% 0" }}>
            <Link className="button rexer-button is-size-4" to="/en/">
              Come back home
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PolicyPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
